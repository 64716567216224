:root {
  --nav-link: #fff;
  --nav-link-active: hsla(0, 0%, 100%, 0.6) !important;
  --white-color: #fff;
  --bg-header: #afafaf url(./assets/123.jpg);
  --title-color: #fff;
  --box-shadow-card: 1px 2px 6px rgb(0 0 0 / 10%);
}
header {
  background-color: rgba(29, 21, 40, 0.09) !important;
  -webkit-backdrop-filter: blur(0.9375rem) !important;
  backdrop-filter: blur(0.9375rem) !important;
  background: #ddd;
  z-index: 999;
}
h4,
h5 {
  color: #fff;
}
.border2 {
  border: 1px solid var(--title-color) !important;
  background: var(--title-color) !important;
}
.border2:hover {
  border: 1px solid var(--nav-link-active) !important;
  background: var(--nav-link-active) !important;
}
.border2 a {
  color: var(--white-color) !important;
}
.btn-outline-primary {
  color: var(--white-color) !important;
  border: 1px solid var(--white-color) !important;
}
body {
  margin: 0;
  font-family: poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -webkit-radial-gradient(
      0% 100%,
      ellipse cover,
      rgba(104, 128, 138, 0.4) 10%,
      rgba(138, 114, 76, 0) 40%
    ),
    linear-gradient(to bottom, rgba(57, 173, 219, 0.25) 0%, #0f0335 100%),
    linear-gradient(142deg, #710f7a, #09031c) !important;
  color: #fff !important;
}

button {
  width: fit-content;
}

button:focus,
button:active {
  box-shadow: none !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

*:focus::placeholder {
  opacity: 0;
}

input,
textarea {
  border-color: #373737 !important;
}
@media (max-width: 575.98px) {
  .zoomM {
    zoom: 0.89;
  }
}
@media (max-width: 767.98px) {
  .mobileS {
    flex-direction: column;
    gap: 20px;
  }

  .smH {
    height: 80px !important;
  }
  .disF {
    margin-top: 2rem;
  }
  .mx--5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  header {
    zoom: 0.8;
  }
  .d-grid-al {
    display: grid;
    grid-template-columns: 100% !important;
  }
}

.nav-link {
  color: var(--nav-link) !important;
  font-weight: 500 !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--nav-link-active) !important;
}
.bgB {
  border: 0 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.6rem !important;
  background-color: rgba(255, 13, 117, 0.5) !important;
  background-image: linear-gradient(
    297deg,
    #ff0d75,
    rgba(58, 11, 118, 0.5)
  ) !important;
  perspective-origin: 100% 50% !important;
  transform-origin: 100% 50% !important;
  font-size: 1rem !important;
  font-weight: 500;
  text-align: center;
}
.font-weight-500 {
  font-weight: 500;
}
.text-primary {
  color: #ae1fb2 !important;
}
.bgTitle {
  /*background: var(--bg-header);*/
  padding-top: 60px !important;
  padding-bottom: 50px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  text-align: center;
}
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active,
.btn:hover {
  background: var(--white-color) !important;
  border-color: var(--white-color) !important;
  color: #000 !important;
}
.card {
  background-color: hsla(0, 0%, 100%, 0.1) !important;
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.13) !important;
  border-radius: 14px !important;
}
.card-header:first-child,
.card-footer:last-child {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}
.pagination {
  color: #fff !important;
  background-color: transparent !important;
}

.cardS .card-header:first-child,
.cardS .card-footer:last-child {
  background-color: transparent !important;
  border-color: transparent !important;
}
.card span {
  font-size: 16px;
  color: #fff;
}
.cardText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cardTextC {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timeS {
  background: #fff;
  color: #000 !important;
  padding: 5px;
  border-radius: 6px;
  min-width: 147px;
  text-align: center;
}
.fw-bold1 {
  font-weight: 500 !important;
}
.card a {
  text-decoration: none !important;
  color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.colorT {
  color: #0d3885 !important;
}
.cardS span {
  color: #ae1fb2 !important;
  font-weight: 600;
}

.d-grid-al {
  display: grid;
  grid-template-columns: 40% 25%;
}
.cPrim {
  color: var(--nav-link-active);
}
.cardS {
  padding: 15px !important;
  box-shadow: -1.04px 7.891px 51px 0px rgb(4 30 129 / 14%) !important;
  border: 0.0625rem solid hsla(0, 0%, 100%, 0.13) !important;
  border-radius: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.1) !important;
  min-height: 310px;
}
.cardS h2 {
  color: #fff;
}
.cardS .colorT {
  color: #fff !important;
  font-weight: 600;
}
.card {
  box-shadow: var(--box-shadow-card);
  padding: 10px 0;
}
.cardS img {
  position: absolute;
  width: 75px;
  top: -31px;
  right: 0;
  left: 37%;
}

.cardS p {
  color: #fff !important;
}

.textBC {
  color: var(--title-color);
}

.textBC {
  color: var(--title-color);
}
.fw-boldT {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--nav-link-active);
}
.alignTc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}
.card p,
.card h1 {
  font-size: 16px;
  color: #fff;
}
.shodow {
  max-height: 70px;
  border-radius: 6px;
  max-width: 150px;
  margin-left: 2px;
}
footer {
  background: #0f0335;
  color: #fff;
  border-top: 0.0625rem solid hsla(0, 0%, 100%, 0.13);
  min-height: 45px;
  margin-top: 0.75rem;
}
.currentA {
  top: 105% !important;
  right: calc(100% + -100px) !important;
  background-color: #fff !important;
  opacity: 1 !important;
  color: var(--nav-link-active) !important;
  filter: none !important;
  font-size: 12px !important;
}
.modal-footer {
  border-top: none !important;
  justify-content: center !important;
}
.modal-content {
  padding: 15px 0;
  background-color: #1976d2 !important;
}
.borderState {
  box-shadow: 0 0 10px;
}

/* Remove arrows from inputs type number */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
a {
  text-decoration: none !important;
}

.section {
  min-height: calc(100vh - 70px);
  width: 100%;
}
.waletNft {
  border: 1px solid var(--nav-link-active);
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500 !important;
  padding: 5px 10px;
}
.linkW {
  color: var(--nav-link-active) !important;
}
.memberM {
  background: var(--white-color);
}
.memberM:hover {
  zoom: 1.1;
}
.mx--5 {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}
.btn-check:focus + .btn,
.btn:focus {
  color: var(--bs-btn-hover-color) !important;
}
.active > .page-link,
.page-link.active {
  background-color: transparent !important;
  border: 1px solid var(--nav-link-active) !important;
}

.cardS:has(.bronzeS) .h3 {
  font-weight: 600;
  font-size: 24px !important;
}
.cardS:has(.silverS) .h3 {
  font-weight: 600;
  font-size: 24px !important;
}
.cardS:has(.goldS) .h3 {
  font-weight: 600;
  font-size: 24px !important;
}
.cardS .card-body {
  text-align: center;
}
.cardS .colorT {
  font-size: 20px;
}
.card .card-body {
  color: #fff;
  text-align: center;
}
.cP p {
  margin-bottom: 0 !important;
}
.div1 {
  position: absolute;
  top: -11px;

  bottom: 10px;
  right: 0;
  left: 0;
}
.div2 {
  z-index: 44;
  display: flex;

  position: absolute;
  left: 19px;
  right: 0;
  top: 68%;
}
.div1 img {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  max-height: 200px;
}
.bgc1 {
  position: absolute;
  left: -60%;
  top: 9rem;
  z-index: -10;
  width: 94rem;
  height: 70%;
}

.bgc2 {
  position: absolute;
  right: 0%;

  z-index: -10;
  /* width: 94rem; */
  height: 70%;
}

.page-link {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}
.disF {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.disF span {
  font-size: 14px;
  color: #cecae3;
}
.bodyP {
  min-height: 350px;
}

@media (min-width: 1400px) {
  .xlH {
    height: 235px !important;
  }
}
.cardS button {
  font-size: 26px !important;
}
.card button {
  font-size: 18px;
}
.tableHi {
  box-shadow: -1.04px 7.891px 51px 0px rgb(4 30 129 / 14%);
}
.sect1 {
  box-shadow: -1.04px 7.891px 51px 0px rgb(4 30 129 / 5%);
  padding: 20px 10px;
  min-width: 290px;
  min-height: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 82px;
  border-bottom-right-radius: 10px;
}
.sect1 h6 {
  color: var(--nav-link-active);
}
.modalWallet .modal-content {
  background-color: var(--nav-link-active) !important;
}
.tokenTa {
  cursor: pointer;
}

.web3modal-modal-lightbox {
  z-index: 1000 !important;
}
.kyc {
  text-align: center;
  padding: 10px;
  background: #1976d2;
  color: #fff;
}
