@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
  vertical-align: middle;
  border-width: 2px;
  border-style: solid;
  border-top-color: transparent;
}

.white {
  border-color: white;
  border-top-color: transparent;
}

.dark {
  border-color: var(--nav-link-active);
  border-top-color: transparent;
}
